import parse from "html-react-parser"
import React from "react"
import SVGIcon from "../SVGIcon"
import "./CustomerFeedback.scss"

const CustomerFeedback = props => {
  const { heading, content } = props
  return (
    <div className="review-box p16">
      <p className="heading text-center fw-bold mx-auto">{heading}</p>
      <div className="parent-grid">
        {content.map((item, idx) => {
          return (
            <div className={`grid grid-${idx + 1} position-relative`}>
              {parse(item.html)}
              <SVGIcon name="angry" className="angry-emoji" />
            </div>
          )
        })}
      </div>
      <div className="p14">
        <p className="text-center text-grey mt-0 mb-0">
          Sourced from reddit <SVGIcon name="reddit-colored" />
        </p>
      </div>
    </div>
  )
}

export default CustomerFeedback
