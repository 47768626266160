import { css } from "@emotion/react"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import "react-multi-carousel/lib/styles.css"
import SVGIcon from "../SVGIcon"
import CarouselComponent from "../carousel-component"

function TestimonialV5(props) {
  const { testimonial } = props

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const [wireVisible, setWireVisible] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleVisibility)
    return function cleanup() {
      window.removeEventListener("scroll", handleVisibility)
    }
  }, [])

  const handleVisibility = () => {
    const springWireTop = document
      .getElementsByClassName("spring-wire-wrapper")[0]
      .getBoundingClientRect().top
    if (springWireTop < 400) setWireVisible(true)
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.7,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2.05,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 1.2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <section className="multiple-5 prelative">
        <div className="d-dsk spring-wire-wrapper">
          {wireVisible && (
            <SVGIcon
              name="block/pinkSpringWire"
              className="spring-wire position-absolute w-100"
            />
          )}
        </div>
        <Container>
          <Row>
            <Col lg={12} className="position-relative">
              <CarouselComponent
                responsive={responsive}
                customTransition="all 1"
                status
                swipeable
                customButtonNew
                arrowClassName="btn-secondary-new"
              >
                {testimonial.map((item, i) => {
                  const { name, title, content, image, bgColor } = item
                  return (
                    <div
                      className={`${screenWidth >= 992 &&
                        wireVisible &&
                        "translate-0"}`}
                      style={{
                        transform:
                          screenWidth >= 992 &&
                          `translate(${50 * (i + 1)}px,0)`,
                        transition: "all 1s ease-in-out",
                      }}
                    >
                      <div
                        className="parent-wrapper br10 prelative"
                        css={css`
                          background: ${bgColor};
                        `}
                      >
                        <div className="parent-box">
                          <p className="font-roboto fw-bold content p20 m-0">
                            {content}
                          </p>
                          <div
                            className={`${
                              screenWidth >= 992
                                ? "justify-content-between align-items-end mt80"
                                : "flex-column mt40"
                            } d-flex mt40`}
                          >
                            <div>
                              <div className="position-relative">
                                <h4 className="name font-roboto fw-800 mb16">
                                  {name}
                                </h4>
                                <div className="position-absolute img-wrapper d-mob">
                                  <div className="prelative">
                                    <SVGIcon
                                      name="badge-testimonial/quotesPurple"
                                      className="position-absolute purple-quote"
                                    />
                                    <img
                                      src={image.url}
                                      className="w-100"
                                      alt={name}
                                    />
                                  </div>
                                </div>

                                <p className="p14 title m-0">{title}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="position-absolute img-wrapper d-dsk">
                          <div className="prelative">
                            <SVGIcon
                              name="badge-testimonial/quotesPurple"
                              className="position-absolute purple-quote"
                            />
                            <img src={image.url} className="w-100" alt={name} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </CarouselComponent>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default TestimonialV5
