import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import "../../../components/common/Video-section/VideoSection.scss"
import SVGIcon from "../SVGIcon"
import YoutubePlayer from "../Youtube-player"

const VideoSection = props => {
  const { heading, videoURL, videoScreenshot, subHeadings } = props
  return (
    <div className="video-section">
      <Container className="Layout-container">
        <Row>
          <h3 className="mb50 text-center fw-bold master-title mx-auto">
            {heading}
          </h3>
          <Col lg={6} className="mx-auto">
            <div className="prelative">
              <YoutubePlayer
                videoURL={videoURL}
                videoScreenshot={{
                  url: videoScreenshot.url,
                }}
              />
              {subHeadings.map((el, ind) => {
                if (ind <= 2) {
                  return (
                    <Fade
                      {...(ind === 2 ? { bottom: true } : { right: true })}
                      duration={1000}
                      delay={100 * (subHeadings.length - ind) * 2}
                      distance={ind === 2 ? "60px" : "30px"}
                    >
                      <div
                        className={`doodle${ind + 1} ${ind !== 2 &&
                          "d-flex"} doodle-font position-absolute text-center d-dsk`}
                      >
                        <SVGIcon name={`home/doodle${ind + 1}`} />
                        <p className="m-0 prelative">{el}</p>
                      </div>
                    </Fade>
                  )
                } else {
                  return (
                    <Fade
                      left
                      duration={1000}
                      delay={100 * (subHeadings.length - ind) * 2}
                      distance="30px"
                    >
                      <div
                        className={`doodle${ind +
                          1} d-flex doodle-font position-absolute text-center d-dsk`}
                      >
                        <p className="m-0 prelative">{el}</p>
                        <SVGIcon name={`home/doodle${ind + 1}`} />
                      </div>
                    </Fade>
                  )
                }
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default VideoSection
