import React from "react"
import { Col, Row } from "react-bootstrap"
import "../../../components/common/Feature-list/FeatureList.scss"
import Slide from "../../../components/utility/slide_content"

const FeatureList = props => {
  const { title, heading, description, image } = props
  return (
    <div className="features">
      <Row style={{ rowGap: "40px" }}>
        <Col lg={4}>
          <Slide delay="200">
            <div className="altv3">
              <h2 className="heading fw-bold">{title}</h2>
            </div>
          </Slide>
        </Col>
        <Col lg={8}>
          <div className="d-flex flex-wrap justify-content-between boxes">
            {heading.map((item, idx) => {
              return (
                <Slide delay="200">
                  <div className="box-wrapper">
                    <div className="d-flex logo-title align-items-center">
                      <img className="icon" src={image[idx].url} alt="icon" />
                      <p className="title fw-bold m-0">{item}</p>
                    </div>

                    <p className="subtext mt16">{description[idx]}</p>
                  </div>
                </Slide>
              )
            })}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default FeatureList
