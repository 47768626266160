import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import "../../styles/component/video-player.scss"
import Close from "./../../assets/images/close-white.png"
import WistiaVideoOnclickEmbed from "./wistia-video-onclick-embed"

const YoutubePlayer = props => {
  const [show, setShow] = useState(false)

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  return (
    <>
      <div
        className="video-player"
        onClick={handleShow}
        role="button"
        tabIndex={0}
      >
        <WistiaVideoOnclickEmbed
          videoScreenshot={props?.videoScreenshot?.url || props.videoScreenshot}
          red={props.red}
        />
      </div>

      <Modal
        className="yt"
        show={show}
        onHide={() => setShow(false)}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <img
          src={Close}
          alt="close"
          className="close-icn"
          onClick={handleClose}
          decoding="async"
        />
        <Container className="yt-vdo">
          <iframe
            className="video"
            src={`${props.videoURL}?rel=0&autoplay=1`}
            frameborder="0"
            allowfullscreen="allowfullscreen"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </Container>
      </Modal>
    </>
  )
}

export default YoutubePlayer
