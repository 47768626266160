import React from "react"
import PulsatingRing from "../utility/pulsating_ring"
import SVGIcon  from "../../components/common/SVGIcon"
import LazyLoad from "react-lazyload"

function WistiaVideoOnclickEmbed(props) {
  return (
    <>
      <div className="position-relative">
        <LazyLoad once offset={100} height={430}>
          {props.videoScreenshot ? (
            <img
              src={props.videoScreenshot}
              loading="lazy"
              alt="screenshot"
              height="100"
              width="100"
            />
          ) : null}
        </LazyLoad>
        <PulsatingRing />
        {props.red ? <SVGIcon name="home/play-button-white" className="position-absolute play" /> : <SVGIcon name="home/play-button" className="position-absolute play" />}
      </div>
    </>
  )
}

export default WistiaVideoOnclickEmbed
