import { css } from "@emotion/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import parse from "html-react-parser"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Marquee from "react-fast-marquee"
import Fade from "react-reveal/Fade"
import Block from "../components/common/Block/Block.js"
import CTA from "../components/common/CTA"
import ComparisonTable from "../components/common/Comparison-table/ComparisonTable.js"
import CustomerFeedback from "../components/common/Customer-feedback/CustomerFeedback.js"
import FeatureList from "../components/common/Feature-list/FeatureList.js"
import Hero from "../components/common/Hero/Hero"
import PopUpForm from "../components/common/PopUpForm.js"
import SVGIcon from "../components/common/SVGIcon"
import ScrollContainer from "../components/common/Scrolling-container/Scrolling-container.js"
import ScrollContainerV2 from "../components/common/Scrolling-container/ScrollingContainerV2"
import Tools from "../components/common/Tools/Tools"
import VerticalTabs from "../components/common/VerticalTabComponent/VerticalTabs.js"
import VideoSection from "../components/common/Video-section/VideoSection.js"
import BadgesV2 from "../components/common/badges/BadgesV2.js"
import FooterLP from "../components/common/footer-lp.js"
import Frame from "../components/common/frame"
import PopupSignup from "../components/common/popup-sign-up.js"
import TestimonialV5 from "../components/common/testimonial-component/multiple-5.js"
import Testimonial from "../components/common/testimonial-component/testimonial"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/templates/Competitor.scss"
import "../styles/templates/competitorV2.scss"
import "../styles/templates/competitorV4.scss"

const CompetitorVersion4 = ({ pageContext }) => {
  const footerData = useStaticQuery(graphql`
    query footerDetailsQuery {
      SuperOps {
        links(where: { section: "Footer Security" }) {
          linkName
          slug
        }
      }
    }
  `)
  const { links } = footerData.SuperOps

  const { competitor } = pageContext
  const {
    seo,
    hideFooterNavTabs,
    heroSection,
    testimonial,
    components,
    competitorImages,
    competitorName,
  } = competitor
  const fiveStar = [1, 1, 1, 1, 1]
  let navBgColor = heroSection.backgroundColor
  const darkColors = ["#8E97E1", "#4ACDCD"]

  const ctaProps =
    competitorName === "snmp"
      ? { openPopUp: () => togglePopup() }
      : {
          formOneAPI: process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
          formTwoAPI: process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT,
          signupPopup: true,
        }

  const [popupVisibility, setPopupVisibility] = useState(false)
  const [formVisibility, SetFormVisibility] = useState(false)

  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }

  const handleFormVisibility = () => {
    SetFormVisibility(false)
  }

  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "Name",
      errorMessage: "Please enter a valid name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "company",
      type: "text",
      label: "Company name",
      required: false,
      errorMessage: "Please enter company",
    },
    {
      name: "phone",
      type: "phone",
      label: "Mobile number",
      required: false,
      errorMessage: "Please enter a mobile number",
    },
  ]

  navBgColor = navBgColor.slice(
    navBgColor.indexOf("#"),
    navBgColor.indexOf("#") + 7
  )

  const ChildHero = props => {
    return (
      <div className={`marq-wrap `}>
        <Marquee pauseOnClick pauseOnHover gradient={false}>
          {testimonial?.testimonialDatas?.map((item, i) => {
            return (
              <Link to={item?.link} target="_blank">
                <Col lg={6} className="marq-child d-flex prelative">
                  <SVGIcon name="g2_logo" className="logo position-absolute" />
                  <div className="p16 review-container w-100 d-flex flex-column justify-content-between">
                    <p className="text-grey review-text m-0">
                      {item.description}
                    </p>
                    <div className="author-star d-flex align-items-center justify-content-between prelative">
                      <div className="d-flex align-items-center cg-10">
                        <img src={item?.image?.url} />
                        <div className="w-100">
                          <p className="review-author fw-600 m-0">
                            {item.name}
                          </p>
                          <div className="d-flex justify-content-between p14">
                            {item?.name != "Nil" ? (
                              <p className="m-0 review-job">{item?.title}</p>
                            ) : (
                              <p className="m-0 invisible review-job">
                                {item?.title}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex star">
                        {fiveStar.map(() => {
                          return <SVGIcon name="star" />
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Link>
            )
          })}
        </Marquee>
      </div>
    )
  }

  return (
    <div
      className={`competitor-new competitor-4 Competitor-page ${
        competitorName === "snmp" ? "snmp" : ""
      } ${hideFooterNavTabs === "Yes" ? "hide-nav-tabs" : ""}`}
    >
      <Frame
        seo={seo}
        newButton
        IsFooterVisble={hideFooterNavTabs === "Yes" && "No"}
      >
        <header>
          <Navigation
            page="Landing page"
            color={navBgColor}
            primaryButtonText="GET STARTED FOR FREE"
            // formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            // formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            signupPopup={competitorName === "snmp" ? false : true}
            newButton
            disallowCommonDomains={hideFooterNavTabs === "Yes" && true}
          />
        </header>

        <div>
          <PopUpForm
            visibility={popupVisibility}
            togglePopup={val => {
              setPopupVisibility(val)
            }}
            formData={formdata}
            endpoint={process.env.HUBSPOT_SNMP_ENDPOINT}
            url={process.env.HUBSPOT_SNMP_URL}
            formName="SNMP early access form- Superops"
            successMsg="Early access program registration successful!"
            successMsg2="You’re in line! Thank you for your interest in trying out our network monitoring—we’ll reach out to you soon to get you on board!"
            additionalFormProps={{ IpStackData: true }}
          />
        </div>

        <section>
          <div
            className="d-flex justify-content-center"
            css={css`
              .bg-lines {
                background-image: url(${heroSection.backgroundImage?.url &&
                  heroSection.backgroundImage.url});
              }
            `}
          >
            <Hero
              heroClassName="marquee-hero"
              heroCol={10}
              type={heroSection.type}
              backgroundImage={heroSection.backgroundColor}
              backgroundLines={heroSection.backgroundImage?.url}
              tag={heroSection?.tag}
              heading={heroSection.heading}
              description={heroSection.content}
              maxHeadingWidth="824px"
              maxDescriptionWidth="48ch"
              primaryButtonText={heroSection.primaryButtonText}
              primaryButtonLink={heroSection.primaryButtonLink}
              formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
              formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
              competitorImage={heroSection.heroImage?.url}
              benefits={heroSection.heroExcerptList}
              signupFormCtaText="GET STARTED FOR FREE"
              children={testimonial ? <ChildHero /> : null}
              // inputEmail
              newButton
              openPopUp={() => togglePopup()}
              snmp={competitorName === "snmp" ? true : false}
            />
          </div>
        </section>

        {competitorImages.length ? (
          <section className="image-section down-to-up-5">
            <img src={competitorImages[0].url} className="w-100" />
          </section>
        ) : null}

        {components.map(field => {
          switch (field.__typename) {
            case "SuperOps_Card": {
              if (field.cardType === "Comparison_scroll")
                return <ScrollContainerV2 data={field} disallowCommonDomains />
              else if (field.cardType === "Features")
                return (
                  <section className="mt80">
                    <Container className="Layout-container">
                      <FeatureList
                        title={field.title[0]}
                        heading={field.text}
                        description={field.subtext}
                        image={field.image}
                      />
                    </Container>
                  </section>
                )
            }

            case "SuperOps_MultipleCard": {
              if (field.cardType === "Double_cards") {
                return (
                  <div className="double-cards">
                    <Container className="Layout-container">
                      <Row
                        className="justify-content-center"
                        style={{ gap: "70px" }}
                      >
                        {field.cards.map((item, i) => {
                          return (
                            <Col
                              lg={5}
                              className={`card-no ${i ? "two" : "one"}`}
                            >
                              <Fade delay={130} duration="500">
                                <p
                                  className="tag fw-bold p12"
                                  css={css`
                                    background: linear-gradient(
                                      90deg,
                                      ${darkColors[i]} -60%,
                                      rgba(255, 255, 255, 0) 80%
                                    );
                                  `}
                                >
                                  {item.cardName}
                                </p>
                              </Fade>
                              <Fade delay={130} duration="500">
                                <div className="altv3">
                                  <h2 className="font-roboto heading mb30 mt32">
                                    {item.heading}
                                  </h2>
                                </div>
                              </Fade>

                              <Fade delay={130} duration="500">
                                <p className="p16 content mb30 down-to-up-3">
                                  {parse(item.content[0].html)}
                                </p>
                              </Fade>

                              <div className="d-flex flex-wrap lists">
                                {item.text.map(a => {
                                  return (
                                    <Fade delay={130} duration="500">
                                      <div className="list-item d-flex align-items-center">
                                        <SVGIcon
                                          name={i ? "greenTickV2" : "deleteBtn"}
                                          className="svg-icon"
                                        />
                                        <p className="m-0">{a}</p>
                                      </div>
                                    </Fade>
                                  )
                                })}
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    </Container>
                  </div>
                )
              } else
                return (
                  <div className="multiple-card">
                    <ScrollContainer
                      data={field.cards}
                      topValue="320"
                      showIndexNum
                      leftColumn={5}
                      rightColumn={7}
                      justifyEvenly
                      wrap
                    />
                  </div>
                )
            }

            case "SuperOps_Testimonial": {
              return (
                <section className="award-testimonial">
                  <Slide delay={200}>
                    <BadgesV2
                      heading={field.testimonialSectionHeading}
                      images={field.testimonialIcons}
                    />
                  </Slide>

                  {field.testimonialType === "multiple_5" ? (
                    <TestimonialV5 testimonial={field.testimonialDatas} />
                  ) : (
                    <Testimonial type="customer-quote" testimonial={field} />
                  )}
                </section>
              )
            }

            case "SuperOps_FaqComponent": {
              return <VerticalTabs data={field} />
            }

            case "SuperOps_Table": {
              return (
                <section className="pb80 mt60">
                  <Slide delay="200">
                    <Container className="Layout-container">
                      <ComparisonTable
                        heading={field.tableHeading}
                        description={field.tableDescription}
                        tableContents={field.tableComponent.raw}
                        competitorLogo={competitor.heroSection.heroImage?.url}
                      />
                    </Container>
                  </Slide>
                </section>
              )
            }

            case "SuperOps_Benefit": {
              if (field.benefitType === "Video")
                return (
                  <section className="mt80">
                    <VideoSection
                      heading={field.benefitsHeading[0]}
                      videoURL={field.benefitsTitle}
                      videoScreenshot={field.benefitsIcon[0]}
                      subHeadings={field.benefitsExcerpt}
                    />
                  </section>
                )
              else if (field.benefitType === "Feedback")
                return (
                  <section className="feedback mt80">
                    <Container className="Layout-container mt60">
                      <div className="feedback">
                        <Slide delay="200">
                          <CustomerFeedback
                            heading={field.benefitsTitle}
                            content={field.benefitsContent}
                          />
                        </Slide>
                      </div>
                    </Container>
                  </section>
                )
              else if (field.benefitType === "Tools")
                return (
                  <>
                    <Tools
                      heading={field.benefitsTitle}
                      title={field.benefitsHeading}
                      description={field.benefitsExcerpt}
                      image={field.benefitsIcon}
                    />
                  </>
                )
            }

            case "SuperOps_Block": {
              return (
                <div
                  className={`graph-block altv3 ${
                    field.blockTextPosition === "Right" ? "text-right" : ""
                  }`}
                >
                  {field.blockTag && (
                    <Container className="Layout-container">
                      <h2 className="title fw-bold mx-auto text-center mt80">
                        {field.blockTag}
                      </h2>
                    </Container>
                  )}

                  <Block
                    html={field.blockContent?.html}
                    heading={field.blockHeading}
                    description={field.blockDescription}
                    imageTitle={field.blockImageTitle}
                    image={field.blockImage}
                    isImage={field.blockIsIllustration}
                    isVideo={field.blockIsVideo}
                    lgLeft={field.blockTextPosition === "Right" ? 3 : 6}
                    lgRight={field.blockTextPosition === "Right" ? 3 : 6}
                  />
                </div>
              )
            }

            case "SuperOps_CtaFeature": {
              return (
                <section className="mt80">
                  <CTA
                    data={[field.ctaBox]}
                    className="Layout-container"
                    lgLeft={7}
                    lgRight={4}
                    rightStyles={{ alignItems: "center" }}
                    newButton
                    newDesign
                    {...ctaProps}
                    disallowCommonDomains
                    openPopUp={
                      hideFooterNavTabs !== "Yes"
                        ? () => handleFormVisibility()
                        : null
                    }
                    // openPopUp={() => togglePopup()}
                    // formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
                    // formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
                    // signupPopup
                  />
                </section>
              )
            }
            default:
              return null
          }
        })}

        <div>
          <PopupSignup
            visibility={formVisibility}
            togglePopup={handleFormVisibility}
            disallowCommonDomains
          />
        </div>

        {hideFooterNavTabs === "Yes" && <FooterLP items={links[0]} />}
      </Frame>
    </div>
  )
}

export default CompetitorVersion4
