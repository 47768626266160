import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Links from "./links"

function FooterLP(props) {
  var d = new Date()
  var currentYear = d.getFullYear()
  return (
    <div className="footer-lp">
      <Container>
        <Row>
          <Col lg={6} className="left">
            <span className="p12">
              <p> © {currentYear} SuperOps Inc. All rights reserved </p>
            </span>
          </Col>
          <Col lg={6} className="right">
            <Links items={props.items} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FooterLP
