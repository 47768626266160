import parse from "html-react-parser"
import React from "react"
import { Col, Row, Table } from "react-bootstrap"
import tableData from "../../utility/tableData"
import SVGIcon from "../SVGIcon"
import "./ComparisonTable.scss"

const ComparisonTable = props => {
  const { heading, description, tableContents, competitorLogo } = props
  return (
    <div className="table-box p16">
      {description && description.html.length > 7 ? (
        <>
          <h4 className=" fw-bold heading">{heading}</h4>
          <div className="description"> {parse(description.html)}</div>
        </>
      ) : (
        <h5 className="table-heading mx-auto text-center fw-bold">{heading}</h5>
      )}

      <Row className="justify-content-center">
        <Col lg={10}>
          <div className="table-wrapper">
            <Table className="comparison-table" borderless>
              <tbody>
                <tr>
                  <th className="bg-blue">
                    <p className="field-name fw-bold">FEATURES</p>
                  </th>
                  <th className="text-center">
                    <SVGIcon name="spsLogoDarkNew" width="142px" />
                  </th>
                  <th className="text-center">
                    <div className="img-wrapper mx-auto">
                      <img src={competitorLogo} className="w-100" />
                    </div>
                  </th>
                </tr>
                {tableData(tableContents).map((el, idx) => {
                  return (
                    <tr>
                      <td className="bg-blue">
                        <p className="feature-name">{el[0]}</p>
                      </td>
                      <td className="text-center yes-no p12">
                        {el[1].toLowerCase() === "yes" ? (
                          <SVGIcon name="tick-round" />
                        ) : el[1].toLowerCase() === "no" ? (
                          <SVGIcon name="cross-round" />
                        ) : (
                          <p className="feature-data fst-italic fw-500">
                            {el[1]}
                          </p>
                        )}
                      </td>
                      <td className="text-center yes-no p12">
                        {el[2].toLowerCase() === "yes" ? (
                          <SVGIcon name="tick-round" />
                        ) : el[2].toLowerCase() === "no" ? (
                          <SVGIcon name="cross-round" />
                        ) : (
                          <p className="feature-data fst-italic fw-500">
                            {el[2]}
                          </p>
                        )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ComparisonTable
