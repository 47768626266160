import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slide from "../../../components/utility/slide_content"
import PopupSignup from "../popup-sign-up"
import "./Scrolling-container.scss"

const ScrollContainerV2 = props => {
  const { data } = props
  const [screenWidth, setScreenWidth] = useState(0)
  const [popup, setPopup] = useState(false)

  const togglePopUp = () => {
    setPopup(!popup)
  }
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  const StickyCard = props => {
    const { idx, heading, title, content } = props
    const ifCtaCard = isNaN(parseInt(heading))

    return (
      <>
        <div
          className={`Sticky-cards mx-auto ${ifCtaCard ? "scroll-cta" : ""} ${
            idx !== 0 && screenWidth >= 992 ? "set-box-shadow" : ""
          }`}
        >
          <p className="number text-center fw-bold">{heading}</p>
          {isNaN(parseInt(title)) && (
            <div className="p18">
              <p className="heading text-center mx-auto">{title}</p>
            </div>
          )}
          <div className="competitor-list">
            <div className="list-content">{parse(content.html)}</div>
          </div>

          {ifCtaCard && (
            <center>
              <button className="btn btn-primary-new white-new white" onClick={()=>setPopup(!popup)}>
                {"GET STARTED FOR FREE"}
              </button>
            </center>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="scrolling-container">
        {popup && <PopupSignup
          visibility={popup}
          togglePopup={togglePopUp}
          formAPI={props.formAPI}
          disallowCommonDomains={props.disallowCommonDomains}
        />}
        <section className="Sticky-cards-section">
          <Container className="Layout-container position-relative">
            <div className="altv3">
              <Slide delay="200">
                <h2 className="scroll-heading text-center mx-auto mb90">
                  {data.cardName}
                </h2>
              </Slide>
            </div>
            <Row>
              <Col lg={6}>
                <div className="cards-col">
                  {data.heading.map((el, idx) => {
                    return (
                      <div className={`list-wrapper cards-wrapper`}>
                        <StickyCard
                          idx={idx}
                          heading={el}
                          title={data.title[idx]}
                          content={data.content[idx]}
                        />
                      </div>
                    )
                  })}
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex flex-column align-items-end d-dsk">
                  {data.image.map((el, idx) => {
                    return (
                      <div className="d-flex align-items-center img-wrapper">
                        <img src={el?.url} className="w-100 h100" alt="image" />
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default ScrollContainerV2
