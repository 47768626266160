import React from "react"
import { Container, Row } from "react-bootstrap"
import "./Tools.scss"

const Tools = props => {
  const { heading, title, description, image } = props
  return (
    <div className="tools">
      <Container className="Layout-container">
        <div className="altv3">
          <h2 className="heading text-center mx-auto fw-bold mb50">
            {heading}
          </h2>
        </div>
        <Row className="card-row parent-grid">
          {title.map((item, idx) => {
            return (
              <>
                <div className={`card-wrapper grid-${idx + 1}`}>
                  <div className="img-wrapper">
                    <img src={image[idx].url} alt="image" className="w-100" />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="p24">
                      <p className="title fw-bold">{item}</p>
                    </div>
                    <p className="p16 description">{description[idx]}</p>
                  </div>
                </div>
              </>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default Tools
