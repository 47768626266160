import React from "react"
import { Container } from "react-bootstrap"
import Tilt from "react-parallax-tilt"
import Reveal from "react-reveal/Reveal"
import "../../../components/common/badges/BadgesV2.scss"

const BadgesV2 = props => {
  const { heading, images } = props
  return (
    <Container className="awards Layout-container">
      <div className="testimonials-section d-flex justify-content-between flex-wrap">
        <h2 className="testimonial-title fw-bold">{heading}</h2>
        <div className="d-flex cg30 justify-content-center d-dsk">
          {images.map((img, i) => {
            return (
              <Reveal effect="tiltImg">
                <Tilt>
                  <div>
                    <img
                      decoding="async"
                      src={img.url}
                      alt="awd"
                      className="image"
                    />
                  </div>
                </Tilt>
              </Reveal>
            )
          })}
        </div>
        <div
          className="d-mob d-flex justify-content-between align-items-center w-100"
        >
          {images.map((img, i) => {
            return (
              <Reveal effect="tiltImg">
                <Tilt>
                  <img
                    decoding="async"
                    src={img.url}
                    alt="awd"
                    className="image"
                  />
                </Tilt>
              </Reveal>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default BadgesV2
