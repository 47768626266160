// Pass the table in raw format and this gives the table data in format of
// [[00, 01, 02], [10, 11, 12], [20, 21, 22]]

const tableData = raw => {
  let tableRaw = raw
  let tableChildren = tableRaw.children.filter(el => el.type === "table")[0]
    ?.children[0].children

  let rowParent = tableChildren
    .map(el => el.children)
    .map(el => el.map(el => el.children))

  let textArray = rowParent.map(el =>
    el.map(x => x.map(y => y.children.map(u => u.text)))
  )
  let text = textArray.map(el => el.map(x => x[0]).flat())

  return text
}

export default tableData

// getting tableData from HTML
// var dom = document.createElement("div")
//     dom.innerHTML = competitor.tableComparison.tableComponent.html
//     let table = dom.getElementsByTagName("table")[0]
//     let data = [...table.rows].map(t => [...t.children].map(u => u.innerText))
//     return data
